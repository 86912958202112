import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomButton from '../custom-button/custom-button.component';

const Empty = ({ message }) => {
	const history = useHistory();

	const handleClick = () => {
		history.push('/');
	};

	return (
		<div className='text-center my-20 text-gray-600'>
			<h1 className='text-2xl font-bold'>
				<svg
					className='w-8 h-6 md:w-10 md:h-8 fill-current inline'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						className='heroicon-ui'
						d='M17 16a3 3 0 11-2.83 2H9.83a3 3 0 11-5.62-.1A3 3 0 015 12V4H3a1 1 0 110-2h3a1 1 0 011 1v1h14a1 1 0 01.9 1.45l-4 8a1 1 0 01-.9.55H5a1 1 0 000 2h12zM7 12h9.38l3-6H7v6zm0 8a1 1 0 100-2 1 1 0 000 2zm10 0a1 1 0 100-2 1 1 0 000 2z'
					/>
				</svg>
				<span className='ml-2'>{message}</span>
			</h1>
			<div className='my-4'>
				<CustomButton onClick={handleClick} inverted checkout>
					Shop Now
				</CustomButton>
			</div>
		</div>
	);
};

export default Empty;
