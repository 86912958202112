import React from 'react';

import Forgot from '../forgot/forgot.components';

const AccountSecurityForm = ({ title, handleSecurityModalToggle }) => {
	return (
		<div className='bg-white mx-5 px-6 py-10 w-full sm:w-3/4 md:w-1/2 xl:w-1/3'>
			<div className='flex justify-between'>
				<div className='text-lg font-bold'>{title}</div>
				<div
					className='font-extrabold cursor-pointer'
					onClick={() => {
						handleSecurityModalToggle(false);
					}}
					onKeyPress={() => {
						handleSecurityModalToggle(false);
					}}
					role='button'
					tabIndex='0'>
					x
				</div>
			</div>
			<div className='mt-4'>
				<Forgot />
			</div>
		</div>
	);
};

export default AccountSecurityForm;
