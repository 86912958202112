import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomButton from '../custom-button/custom-button.component';
import { setOrdersAsync } from '../../redux/orders/orders.actions';

import { selectCurrentUser } from '../../redux/user/user.selectors';

const Confirmation = ({ handleConfirmation, currentUser, _setOrdersAsync }) => {
	const history = useHistory();

	const handlePlaceOrder = async () => {
		await _setOrdersAsync(currentUser._id);
		handleConfirmation(false);
		history.push('/orders');
	};

	return (
		<div className='bg-white mx-5 px-6 py-5 w-3/4 sm:w-1/2 lg:w-1/3 xl:w-1/4'>
			<div className='flex justify-end'>
				<div
					className='font-extrabold cursor-pointer'
					onClick={() => {
						handleConfirmation(false);
					}}
					onKeyPress={() => {
						handleConfirmation(false);
					}}
					role='button'
					tabIndex='0'>
					x
				</div>
			</div>
			<div className='text-md md:text-lg flex flex-col justify-center items-center mt-2'>
				<div className='mb-6 text-center'>
					<p className='font-bold'>Confirm Order ?</p>
					<p className='font-light italic'>This action cannot be cancelled</p>
				</div>
				<div className='w-1/2'>
					<CustomButton
						full
						onClick={() => {
							handlePlaceOrder();
						}}>
						Yes
					</CustomButton>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_setOrdersAsync: (user_id) => dispatch(setOrdersAsync(user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
