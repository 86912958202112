import React from 'react';
import { Link } from 'react-router-dom';

import Forgot from '../../components/forgot/forgot.components';

const ForgotPassword = () => {
	return (
		<div className='container flex-grow'>
			<div className='px-5 sm:px-20 lg:px-40 xl:px-64 py-20 '>
				<div className='text-sm lg:text-base p-6 sm:p-8 lg:px-16 bg-white text-center shadow-lg'>
					<h1 className='font-bold text-base sm:text-lg md:text-xl'>
						Forgot Password
					</h1>
					<Forgot />
					<div className='mt-4 text-brand-color underline text-sm'>
						<Link to='/login'>Log in to your account</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
