/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

const CustomButton = ({
	children,
	inverted,
	invertedGray,
	checkout,
	wide,
	full,
	disabledButton,
	handleClick,
	...otherProps
}) => {
	return (
		<div>
			{invertedGray ? (
				<button
					className={`
						${wide ? 'w-1/2' : ''}
						${full ? 'w-full' : ''}
					bg-white text-gray-700 border border-gray-500 text-xs py-2 px-4 md:text-base lg:py-4 lg:px-6 focus:outline-none rounded-md tracking-wide truncate
					`}
					onClick={handleClick}
					{...otherProps}>
					{children}
				</button>
			) : (
				<button
					className={`
						${
							inverted
								? 'bg-white text-brand-color border border-brand-color'
								: 'bg-brand-color text-white border border-transparent'
						}
						${
							checkout
								? 'text-sm py-2 px-8 sm:text-base lg:py-4 lg:px-10 md:text-lg'
								: 'text-xs py-2 px-4 md:text-base lg:py-4 lg:px-6'
						}
						${wide ? 'w-1/2' : ''}
						${full ? 'w-full' : ''}
						${disabledButton ? 'disabled-button' : ''}
						focus:outline-none rounded-md tracking-wide truncate
					`}
					onClick={handleClick}
					{...otherProps}>
					{children}
				</button>
			)}
		</div>
	);
};

CustomButton.propTypes = {
	children: PropTypes.string,
	inverted: PropTypes.bool,
	invertedGray: PropTypes.bool,
	checkout: PropTypes.bool,
	wide: PropTypes.bool,
	full: PropTypes.bool,
	disabledButton: PropTypes.bool,
	handleClick: PropTypes.func,
};

export default CustomButton;
