import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FormInput from '../form-input/form-input.component';
import Modal from '../modal/modal.component';
import ContactForm from '../contact-form/contact-form.component';

import { setCurrentUser } from '../../redux/user/user.actions';
import { setSearchField } from '../../redux/product/product.actions';
import { setInitialData } from '../../redux/cart/cart.actions';

import { selectCartItemsCount } from '../../redux/cart/cart.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import './header.styles.scss';
import Logo from '../../assets/images/logo.png';

import { client } from '../../client';

class Header extends Component {
	constructor() {
		super();

		window.HeaderComponent = this;

		this.state = {
			searchString: '',
			showMenu: false,
			showModal: false,
		};
	}

	setSearchField = () => {
		const { searchString } = this.state;
		const { _setSearchField, history } = this.props;

		if (searchString === '') {
			return;
		}

		_setSearchField(searchString);
		history.push(`/products?search=${searchString}`);
	};

	handleClick = () => {
		this.setSearchField();
	};

	handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			this.setSearchField();
		}
	};

	handleChange = (e) => {
		this.setState({ searchString: e.target.value });
	};

	handleLogout = async () => {
		const { _setCurrentUser, _setInitialData } = this.props;

		await client.logout();
		_setInitialData([]);
		_setCurrentUser(null);
	};

	handleToggleMenu = () => {
		this.setState({ showMenu: !this.state.showMenu });
	};

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	};
	render() {
		const { cartItemsCount, currentUser, location } = this.props;
		const { showMenu, showModal } = this.state;
		const { pathname } = location;
		let name;

		if (currentUser) {
			name = currentUser.name.split(' ');
		}

		return (
			<div
				className={`
				${pathname === '/login' ? ' bg-white' : 'bg-gray-800'}
				border-t-15 sm:border-t-20 lg:border-t-30 border-brand-color sticky top-0 z-10
			`}>
				{pathname !== '/login' ? (
					<div className='flex justify-between items-center container mx-auto'>
						<Link to='/'>
							<img className=' h-12 sm:h-16 md:h-24' src={Logo} alt='logo' />
						</Link>
						<div className='flex mx-2 md:mx-6 lg:mx-2 w-7/10 lg:w-2/3 xl:w-7/10'>
							<FormInput
								type='text'
								placeholder='Search products'
								handleChange={this.handleChange}
								handleKeyDown={this.handleKeyDown}
							/>
							<button
								className='bg-brand-color rounded-r-lg focus:outline-none -ml-2'
								type='button'
								onClick={this.handleClick}>
								<svg
									className='w-8 h-6 md:w-10 md:h-8 fill-current text-white'
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										className='heroicon-ui'
										d='M16.32 14.9l5.39 5.4a1 1 0 01-1.42 1.4l-5.38-5.38a8 8 0 111.41-1.41zM10 16a6 6 0 100-12 6 6 0 000 12z'
									/>
								</svg>
							</button>
						</div>
						<div className='flex cursor-pointer relative'>
							<Link to='/cart'>
								<svg
									className='w-8 h-6 md:w-10 md:h-8 fill-current text-white'
									onClick={this.handleCart}
									onKeyPress={this.handleCart}
									viewBox='0 0 24 24'
									xmlns='http://www.w3.org/2000/svg'>
									<path
										className='heroicon-ui'
										d='M17 16a3 3 0 11-2.83 2H9.83a3 3 0 11-5.62-.1A3 3 0 015 12V4H3a1 1 0 110-2h3a1 1 0 011 1v1h14a1 1 0 01.9 1.45l-4 8a1 1 0 01-.9.55H5a1 1 0 000 2h12zM7 12h9.38l3-6H7v6zm0 8a1 1 0 100-2 1 1 0 000 2zm10 0a1 1 0 100-2 1 1 0 000 2z'
									/>
								</svg>
							</Link>
							<span className='self-start px-1 -ml-1 bg-red-700 text-xs text-white rounded-full'>
								{cartItemsCount}
							</span>
							{currentUser ? (
								<div
									className='flex items-center'
									onClick={this.handleToggleMenu}>
									<svg
										className='w-8 h-6 ml-1 md:w-10 md:h-8 fill cursor-pointer'
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										stroke='#fff'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z'
										/>
									</svg>
									<div className='hidden lg:block text-white text-sm font-semibold'>
										{name[0]}
									</div>
								</div>
							) : (
								<Link to='/login'>
									<div className='flex items-center'>
										<svg
											className='w-8 h-6 ml-1 md:w-10 md:h-8 fill-current text-white'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 24 24'>
											<path
												className='heroicon-ui'
												d='M12 12a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm9 11a1 1 0 0 1-2 0v-2a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v2a1 1 0 0 1-2 0v-2a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v2z'
											/>
										</svg>
										<div className='hidden lg:block text-white text-sm'>
											Sign Up | Login
										</div>
									</div>
								</Link>
							)}
							{showMenu ? (
								<div className='flex flex-col items-end w-24 md:w-32 bg-white absolute right-0 mt-8 md:mt-10 shadow-md dropdown-menu'>
									<Link
										to='/account'
										onClick={this.handleToggleMenu}
										className='text-brand-color text-xs md:text-sm lg:text-base my-1 md:my-2 mx-2 md:mx-3'>
										My Account
									</Link>
									<div className='flex flex-col border-t border-b w-full items-end border-t-gray-700'>
										<Link
											to='/orders'
											onClick={this.handleToggleMenu}
											className='text-xs md:text-sm lg:text-base my-1 md:my-2 mx-2 md:mx-3'>
											Orders
										</Link>
										<a
											onClick={() => {
												this.handleToggleModal();
												this.handleToggleMenu();
											}}
											className='text-xs md:text-sm lg:text-base my-1 md:my-2 mx-2 md:mx-3'>
											Contact Us
										</a>
									</div>
									<a
										onClick={() => {
											this.handleLogout();
											this.handleToggleMenu();
										}}
										className='text-xs md:text-sm lg:text-base my-1 md:my-2 mx-2 md:mx-3'>
										Logout
									</a>
								</div>
							) : null}
						</div>
					</div>
				) : (
					<div className='flex justify-start items-center container mx-auto'>
						<Link to='/'>
							<img className=' h-12 sm:h-16 md:h-24' src={Logo} alt='logo' />
						</Link>
						<div className='text-xl sm:text-2xl lg:text-3xl text-gray-900 ml-3 md:ml-5 font-extrabold'>
							Welcome
						</div>
					</div>
				)}
				<Modal showModal={showModal}>
					<ContactForm
						title='Contact Us'
						handleModalToggle={this.handleToggleModal}
					/>
				</Modal>
			</div>
		);
	}
}

Header.propTypes = {
	_setSearchField: PropTypes.func,
	_setCurrentUser: PropTypes.func,
	_setInitialData: PropTypes.func,
	history: PropTypes.object,
	cartItemsCount: PropTypes.number,
	location: PropTypes.object,
	pathname: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
	cartItemsCount: selectCartItemsCount,
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_setSearchField: (searchString) => dispatch(setSearchField(searchString)),
	_setCurrentUser: (user) => dispatch(setCurrentUser(user)),
	_setInitialData: (data) => dispatch(setInitialData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
