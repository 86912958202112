import React, { Component } from 'react';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import { ToastContainer, toast } from 'react-toastify';

import { client } from '../../client';

import 'react-toastify/dist/ReactToastify.css';

class Forgot extends Component {
	constructor() {
		super();

		this.state = {
			email: '',
		};
	}

	handleChange = (event) => {
		const { value, name } = event.target;

		this.setState({ [name]: value });
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		const { email } = this.state;

		await client.service('reset-password').create({ email });

		this.notify();
		this.setState({ email: '' });
	};

	notify = () =>
		toast.success('Password request sent. Please check your email.', {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});

	render() {
		const { email } = this.state;

		return (
			<>
				<ToastContainer />
				<div className='mt-2'>
					Send us your email address and we will email the instruction to reset
					your password
				</div>
				<form onSubmit={this.handleSubmit}>
					<div className='mt-5'>
						<FormInput
							type='email'
							name='email'
							value={email}
							placeholder='Email'
							handleChange={this.handleChange}
							border
							required
						/>
					</div>
					<div className='mt-3'>
						<CustomButton type='submit' full>
							Send
						</CustomButton>
					</div>
				</form>
			</>
		);
	}
}

export default Forgot;
