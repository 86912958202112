import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import WithSpinner from '../../components/with-spinner/with-spinner.component';
import LoginWrapped from './login-wrapped.component';

import { selectIsFetching } from '../../redux/user/user.selectors';

const LoginWrappedWithSpinner = WithSpinner(LoginWrapped);

const Login = ({ isFetching }) => {
	return <LoginWrappedWithSpinner isLoading={isFetching} />;
};

const mapStateToProps = createStructuredSelector({
	isFetching: selectIsFetching,
});

export default connect(mapStateToProps)(Login);
