import React from 'react';

const PageNotFound = () => {
	return (
		<div className='text-center my-32 flex-grow'>
			<h1 className='text-2xl font-bold'>Page Not Found</h1>
		</div>
	);
};

export default PageNotFound;
