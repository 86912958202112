import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';

import CustomButton from '../../components/custom-button/custom-button.component';
import Modal from '../../components/modal/modal.component';
import AccountDetailsForm from '../../components/account-details-form/account-details-form.components';
import AccountSecurityForm from '../../components/account-security-form/account-security-form.component';

import { selectCurrentUser } from '../../redux/user/user.selectors';

const Account = ({ currentUser }) => {
	const history = useHistory();

	const [showGeneralModal, handleGeneralModalToggle] = useState(false);
	const [showSecurityModal, handleSecurityModalToggle] = useState(false);

	const { _id, name, email } = currentUser;

	const handleClick = () => {
		history.push('/');
	};

	return (
		<div className='container flex-grow'>
			<div className='my-4 text-xs md:text-sm'>
				<span
					className='hover:underline cursor-pointer outline-none'
					role='link'
					tabIndex='0'
					onClick={handleClick}
					onKeyPress={handleClick}>
					Home
				</span>{' '}
				&gt; My Account
			</div>
			<div className='mt-3 bg-white shadow-lg p-5'>
				<h1 className='text-sm md:text-base lg:text-lg font-bold mb-2'>
					General
				</h1>
				<div className='flex justify-between items-center text-xs md:text-sm lg:text-base'>
					<div className='flex flex-col w-3/4'>
						<div className='flex justify-center text-xs md:text-sm lg:text-base py-1 md:py-2 w-full'>
							<div className='w-2/12 lg:w-1/12'>Name:</div>
							<div className='w-10/12 lg:w-11/12 font-bold'>{name}</div>
						</div>
						<div className='flex justify-center text-xs md:text-sm lg:text-base py-1 md:py-2 w-full'>
							<div className='w-2/12 lg:w-1/12'>Email:</div>
							<div className='w-10/12 lg:w-11/12 font-bold'>{email}</div>
						</div>
					</div>
					<div className='w-1/4 text-right'>
						<CustomButton
							onClick={() => {
								handleGeneralModalToggle(true);
							}}>
							Change
						</CustomButton>
					</div>
				</div>
			</div>
			<div className='mt-3 bg-white shadow-lg p-5'>
				<h1 className='text-sm md:text-base lg:text-lg font-bold mb-2'>
					Security
				</h1>
				<div className='flex justify-between items-center text-xs md:text-sm lg:text-base'>
					<div className='w-3/4'>
						<div className='flex justify-center text-xs md:text-sm lg:text-base py-1 md:py-2 w-full'>
							<div className='w-2/12 lg:w-1/12'>Password:</div>
							<div className='w-10/12 lg:w-11/12 font-bold ml-5 sm:ml-0 md:ml-4 lg:ml-8 xl:ml-6 tracking-wider'>
								******
							</div>
						</div>
					</div>
					<div className='w-1/4 text-right'>
						<CustomButton
							onClick={() => {
								handleSecurityModalToggle(true);
							}}>
							Change
						</CustomButton>
					</div>
				</div>
			</div>
			<Modal showModal={showGeneralModal}>
				<AccountDetailsForm
					title='General Details'
					_id={_id}
					name={name}
					email={email}
					handleGeneralModalToggle={handleGeneralModalToggle}
					handleGeneralModalToggle={handleGeneralModalToggle}
				/>
			</Modal>
			<Modal showModal={showSecurityModal}>
				<AccountSecurityForm
					title='Security Details'
					handleSecurityModalToggle={handleSecurityModalToggle}
					handleSecurityModalToggle={handleSecurityModalToggle}
				/>
			</Modal>
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Account);
