import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

const Category = () => {
	const [showMenu, handleMenuToggle] = useState(false);
	const history = useHistory();

	const handleClick = (e) => {
		history.push(`/products?search=${e.target.textContent}`);
	};

	return (
		<Fragment>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				fill='none'
				viewBox='0 0 24 24'
				stroke='black'
				className='sm:hidden fill-current w-5 h-5 mt-3 mx-2 cursor-pointer'
				onClick={() => {
					handleMenuToggle(!showMenu);
				}}>
				<path
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='1'
					d='M4 6h16M4 12h16M4 18h16'
				/>
			</svg>
			<div
				className={`
				${showMenu ? 'block' : 'hidden'}
				sm:hidden mx-1 mt-2 bg-gray-300 rounded-sm py-2
			`}>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category-xs text-xs px-2 py-1'>
					Motorcycle Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category-xs text-xs px-2 py-1'>
					Gasoline Engine Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category-xs text-xs px-2 py-1'>
					Diesel Engine Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category-xs text-xs px-2 py-1'>
					Gear Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category-xs text-xs px-2 py-1'>
					Hydraulic Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category-xs text-xs px-2 py-1'>
					Transmission Fluid
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category-xs text-xs px-2 py-1'>
					Automotive Grease
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category-xs text-xs px-2 py-1'>
					Welding Rod
				</div>
			</div>
			<div className='hidden sm:flex flex-after justify-between flex-wrap mt-4 mx-2'>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category text-xs px-2 py-1 lg:px-1'>
					Motorcycle Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category text-xs px-2 py-1 lg:px-1'>
					Gasoline Engine Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category text-xs px-2 py-1 lg:px-1'>
					Diesel Engine Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category text-xs px-2 py-1 lg:px-1'>
					Gear Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category text-xs px-2 py-1 lg:px-1'>
					Hydraulic Oils
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category text-xs px-2 py-1 lg:px-1'>
					Transmission Fluid
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category text-xs px-2 py-1 lg:px-1'>
					Automotive Grease
				</div>
				<div
					onClick={handleClick}
					onKeyPress={handleClick}
					role='button'
					tabIndex='0'
					className='category text-xs px-2 py-1 lg:px-1'>
					Welding Rod
				</div>
			</div>
		</Fragment>
	);
};

export default Category;
