import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import { client } from '../../client';

class ContactForm extends Component {
	constructor() {
		super();

		this.state = {
			name: '',
			email: '',
			subject: '',
			message: '',
		};
	}

	handleChange = (event) => {
		const { value, name } = event.target;

		this.setState({ [name]: value });
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		const { name, email, subject, message } = this.state;
		const { handleModalToggle } = this.props;

		await client.service('contact').create({
			name,
			email,
			subject,
			message,
		});

		this.setState({
			name: '',
			email: '',
			subject: '',
			message: '',
		});

		handleModalToggle(false);
	};

	render() {
		const { name, email, subject, message } = this.state;
		const { title, handleModalToggle } = this.props;
		return (
			<div className='bg-white mx-5 px-6 py-10 w-full md:w-3/4 xl:w-3/5'>
				<form onSubmit={this.handleSubmit}>
					<div className='flex justify-between'>
						<div className='text-lg font-bold'>{title}</div>
						<div
							className='font-extrabold cursor-pointer'
							onClick={() => {
								handleModalToggle(false);
							}}
							onKeyPress={() => {
								handleModalToggle(false);
							}}
							role='button'
							tabIndex='0'>
							x
						</div>
					</div>
					<div className='flex flex-col sm:flex-row mt-4'>
						<div className='w-full sm:w-1/2'>
							<p className='uppercase text-xs font-bold tracking-wider'>
								Send An Email
							</p>
							<div className='mt-3'>
								<FormInput
									type='text'
									name='name'
									placeholder='Your Name'
									border
									handleChange={this.handleChange}
									value={name}
									required
								/>
							</div>
							<div className='mt-3'>
								<FormInput
									type='email'
									name='email'
									placeholder='Your Email'
									border
									handleChange={this.handleChange}
									value={email}
									required
								/>
							</div>
							<div className='mt-3'>
								<FormInput
									type='text'
									name='subject'
									placeholder='Subject'
									border
									handleChange={this.handleChange}
									value={subject}
									required
								/>
							</div>
							<div className='mt-3'>
								<textarea
									name='message'
									placeholder='Message'
									onChange={this.handleChange}
									value={message}
									className=' resize-none border border-gray-500 w-full h-40 rounded-lg focus:outline-none p-2 md:p-3 text-gray-700 text-xs sm:text-sm md:text-base'
								/>
							</div>
							<div className='mt-3'>
								<CustomButton type='submit' inverted full>
									SEND
								</CustomButton>
							</div>
						</div>
						<div className='w-full sm:w-1/2 flex mt-6 sm:flex-col sm:pl-10 sm:mt-0'>
							<div className='w-1/2 sm:w-full'>
								<p className='uppercase text-xs font-bold tracking-wider mb-2'>
									Phone And Email
								</p>
								<p className='text-sm md:text-base'>
									032 253-0993 <br />
									032 412-5856 <br />
									info@cycloneoil.com
								</p>
							</div>
							<div className='w-1/2 sm:w-full ml-3 sm:ml-0 sm:mt-6'>
								<p className='uppercase text-xs font-bold tracking-wider mb-2'>
									Address
								</p>
								<p className='text-sm md:text-base'>
									PHILOIL RESOURCES <br />
									369 LLL JUANA OSMEÑA EXT. <br />
									CEBU CITY
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

ContactForm.propTypes = {
	title: PropTypes.string,
	handleModalToggle: PropTypes.func,
};

export default ContactForm;
