import React from 'react';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ItemCount from '../item-count/item-count.component';

import {
	addItemAsync,
	removeItemAsync,
	clearItemAsync,
} from '../../redux/cart/cart.actions';
import { selectCurrentUser } from '../../redux/user/user.selectors';

const CartItem = ({
	item,
	_addItemAsync,
	_removeItemAsync,
	_clearItemAsync,
	currentUser,
}) => {
	const {
		_id,
		name,
		category,
		classification,
		packing,
		description,
		stocks,
		count,
		countType,
		imageUrl,
	} = item;

	const pluralize = (string) => {
		let plural = '';
		const stringArray = string.split('');

		stringArray.splice(-1, 1, '(', 's', ')');

		stringArray.forEach((letter) => {
			plural += letter;
		});

		return plural;
	};

	return (
		<div className='p-4 md:px-10 lg:px-16 mb-1 bg-white shadow-lg flex'>
			<div className='w-2/5 sm:w-1/3' role='button' tabIndex='0'>
				<Link
					className='flex flex-col md:flex-row items-center'
					to={{
						pathname: `/products/view/${_id}`,
						state: {
							_id,
							name,
							category,
							classification,
							packing,
							description,
							stocks,
							imageUrl,
						},
					}}>
					<img
						className='w-2/3 sm:w-3/5 md:w-1/3 lg:w-1/4 shadow-lg'
						src={`${imageUrl}-300x300.jpg`}
						alt={name}
					/>
					<div className='md:ml-4 lg:ml-6 mt-2 text-sm lg:text-md font-bold whitespace-no-wrap sm:overflow-visible'>
						{name}
						<div
							className={`${
								item.classification === 'none' ? 'hidden' : null
							} text-xs text-gray-600 uppercase font-semibold tracking-wide`}>
							{classification}
						</div>
					</div>
				</Link>
			</div>
			<div className='w-2/5 sm:w-1/3 flex items-center justify-center'>
				<ItemCount
					count={count}
					handleIncrement={() => {
						const plusOne = true;
						_addItemAsync(currentUser._id, item, plusOne);
					}}
					handleDecrement={() => {
						_removeItemAsync(currentUser._id, item);
					}}
					withArrow={true}
				/>
				<div className='ml-2'>{pluralize(countType)}</div>
				{/* <div className='px-2 text-xs md:text-md lg:text-base text-gray-600'>
					{stocks} in stock
				</div> */}
			</div>
			<div className='w-1/5 sm:w-1/3 flex justify-center items-center'>
				<svg
					onClick={() => {
						_clearItemAsync(currentUser._id, item);
					}}
					className='fill-current cursor-pointer text-red-600 w-6 h-6 md:w-8 md:h-8'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 20 20'>
					<path
						fillRule='evenodd'
						d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
					/>
				</svg>
			</div>
		</div>
	);
};

CartItem.propTypes = {
	item: PropTypes.object,
	currentUser: PropTypes.object,
	_addItemAsync: PropTypes.func,
	_removeItemAsync: PropTypes.func,
	_clearItemAsync: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_addItemAsync: (user_id, cartItemToAdd, plusOne) =>
		dispatch(addItemAsync(user_id, cartItemToAdd, plusOne)),
	_removeItemAsync: (user_id, cartItemToRemove) =>
		dispatch(removeItemAsync(user_id, cartItemToRemove)),
	_clearItemAsync: (user_id, cartItemToClear) =>
		dispatch(clearItemAsync(user_id, cartItemToClear)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
