import React from 'react';

const OrderViewItem = ({ item }) => {
	const pluralize = (string) => {
		let plural = '';
		const stringArray = string.split('');

		stringArray.splice(-1, 1, '(', 's', ')');

		stringArray.forEach((letter) => {
			plural += letter;
		});

		return plural;
	};
	return (
		<div className='flex mb-2'>
			<div className='w-1/4'>
				<img
					className='shadow-lg'
					src={`${item.imageUrl}-300x300.jpg`}
					alt={item.name}
				/>
			</div>
			<div className='text-sm ml-2'>
				<div className='font-bold'>{item.name}</div>
				<div
					className={`${
						item.classification === 'none' ? 'hidden' : null
					} text-xs text-gray-600 uppercase font-semibold tracking-wide`}>
					{item.classification}
				</div>
				<div>
					Quanity: {item.count} {pluralize(item.countType)}
				</div>
			</div>
		</div>
	);
};

export default OrderViewItem;
