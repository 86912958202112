import { userActionTypes } from './user.types';
import { client } from '../../client';
import { fetchCartAsync } from '../cart/cart.actions';

export const setCurrentUser = (user) => ({
	type: userActionTypes.SET_CURRENT_USER,
	payload: user,
});

const fetchStart = () => ({
	type: userActionTypes.FETCH_START,
});

export const setErrorMessage = (message) => ({
	type: userActionTypes.SET_ERROR_MESSAGE,
	payload: message,
});

export const setSuccessfulReset = () => ({
	type: userActionTypes.SET_SUCCESSFUL_RESET,
});

const patchCurrentUser = (data) => ({
	type: userActionTypes.PATCH_CURRENT_USER,
	payload: data,
});

export const registerAsync = (name, email, password) => {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			await client
				.service('users')
				.create({ name, email, role: 'customer', password });
			dispatch(loginAsync(email, password));
		} catch (e) {
			const errorMessage =
				e.code === 409
					? 'This email adress is already being used.'
					: 'Something went wrong. Please try again later.';

			dispatch(setErrorMessage(errorMessage));
		}
	};
};

export const loginAsync = (email, password) => {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			const auth = await client.authenticate({
				strategy: 'local',
				client: 'frontend',
				email,
				password,
			});
			await dispatch(setCurrentUser(auth.user));
			dispatch(fetchCartAsync(auth.user._id));
			dispatch(setErrorMessage(null));
		} catch (e) {
			const errorMessage =
				e.code === 401
					? e.message
					: 'Something went wrong. Please try again later.';

			dispatch(setErrorMessage(errorMessage));
		}
	};
};

export const reauthAsync = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			const auth = await client.reAuthenticate();
			await dispatch(setCurrentUser(auth.user));
			dispatch(fetchCartAsync(auth.user._id));
		} catch (e) {
			const errorMessage =
				e.code === 401 ? null : 'Something went wrong. Please try again later.';
			dispatch(setErrorMessage(errorMessage));
		}
	};
};

export const patchCurrentUserAsync = (data) => {
	return async (dispatch) => {
		try {
			const { _id, name, email } = data;

			await client.service('users').patch(_id, { name, email });
			dispatch(patchCurrentUser({ name, email }));
		} catch (e) {
			// TODO: handle laters
			console.log(e);
		}
	};
};
