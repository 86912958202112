import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ToastContainer, toast } from 'react-toastify';

import FormInput from '../../components/form-input/form-input.component';
import CustomButton from '../../components/custom-button/custom-button.component';

import {
	loginAsync,
	registerAsync,
	setErrorMessage,
	setSuccessfulReset,
} from '../../redux/user/user.actions';
import {
	selectErrorMessage,
	selectSuccessfulReset,
} from '../../redux/user/user.selectors';

import { ReactComponent as LoginBanner } from '../../assets/images/login-banner.svg';

class LoginWrapped extends Component {
	constructor() {
		super();

		this.state = {
			name: '',
			email: '',
			password: '',
			form: 'login',
			loginInverted: true,
			loginInvertedGray: false,
			signUpInverted: false,
			signUpInvertedGray: true,
		};
	}

	handleClick = (event, skipSetErrorMessage) => {
		const { name } = event.target;
		const {
			loginInverted,
			loginInvertedGray,
			signUpInverted,
			signUpInvertedGray,
		} = this.state;
		const { _setErrorMessage } = this.props;

		if (loginInverted && name === 'login') {
			return;
		}

		if (signUpInverted && name === 'sign-up') {
			return;
		}

		this.setState({
			form: name,
			loginInverted: !loginInverted,
			loginInvertedGray: !loginInvertedGray,
			signUpInverted: !signUpInverted,
			signUpInvertedGray: !signUpInvertedGray,
		});

		if (!skipSetErrorMessage) {
			_setErrorMessage(null);
		}
	};

	handleChange = (event) => {
		const { value, name } = event.target;

		this.setState({ [name]: value });
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		const { form, name, email, password } = this.state;
		const { _registerAsync, _loginAsync, _setErrorMessage } = this.props;

		try {
			if (form === 'sign-up') {
				await _registerAsync(name, email, password);
			}

			if (form === 'login') {
				await _loginAsync(email, password);
			}
		} catch (error) {
			_setErrorMessage(null);
		}
	};

	notifySuccess = () =>
		toast.success('Password successfully reset.', {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});

	componentDidMount() {
		const { errorMessage } = this.props;

		if (errorMessage === 'This email adress is already being used.') {
			const event = {
				target: {
					name: 'sign-up',
				},
			};
			const skipSetErrorMessage = true;

			this.handleClick(event, skipSetErrorMessage);
		}
	}

	componentDidUpdate() {
		const { successfulReset, _setSuccessfulReset } = this.props;

		if (successfulReset) {
			this.notifySuccess();
			_setSuccessfulReset();
		}
	}

	render() {
		const {
			name,
			email,
			password,
			form,
			loginInverted,
			loginInvertedGray,
			signUpInverted,
			signUpInvertedGray,
		} = this.state;
		const { errorMessage } = this.props;

		return (
			<div className='flex-grow container'>
				<ToastContainer />
				<div className='flex justify-around my-4 lg:my-10'>
					<div className='flex flex-col p-5 sm:py-8 sm:px-20 md:p-2 lg:p-10 w-full md:w-1/2'>
						<div className='flex justify-between'>
							<div className='mr-2 w-full'>
								<CustomButton
									name='login'
									full
									inverted={loginInverted}
									invertedGray={loginInvertedGray}
									handleClick={this.handleClick}>
									I have an account
								</CustomButton>
							</div>
							<div className='ml-2 w-full'>
								<CustomButton
									name='sign-up'
									full
									inverted={signUpInverted}
									invertedGray={signUpInvertedGray}
									handleClick={this.handleClick}>
									I&apos;m a new customer
								</CustomButton>
							</div>
						</div>
						<div className='font-thin mt-4'>
							{form === 'sign-up' ? (
								<div className='text-sm md:text-base'>
									Sign up with your email and password
								</div>
							) : (
								<div className='text-sm md:text-base'>
									Log in with your email and password
								</div>
							)}
						</div>
						<div className='mt-3 text-red-700'>{errorMessage}</div>
						<form onSubmit={this.handleSubmit}>
							{form === 'sign-up' ? (
								<div className='mt-4'>
									<FormInput
										type='text'
										id='name'
										name='name'
										value={name}
										placeholder='Name'
										minLength='3'
										handleChange={this.handleChange}
										border
										required
									/>
								</div>
							) : null}
							<div className='mt-4'>
								<FormInput
									type='email'
									id='email'
									name='email'
									value={email}
									placeholder='Email'
									handleChange={this.handleChange}
									border
									required
								/>
							</div>
							<div className='mt-4'>
								<FormInput
									type='password'
									id='password'
									name='password'
									value={password}
									minLength={`${form === 'login' ? '1' : '6'}`}
									placeholder='Password'
									handleChange={this.handleChange}
									border
									required
								/>
							</div>
							{form === 'sign-up' ? (
								<div className='text-sm mt-8'>
									By signing up, you agree to{' '}
									<span className='text-brand-color'>
										Cyclone&apos;s Terms of Service
									</span>
									<span> &amp; </span>
									<span className='text-brand-color'>Privacy Policy</span>
								</div>
							) : (
								<Link to='forgot-password'>
									<div className='text-sm mt-8 text-brand-color'>
										Forgot password ?
									</div>
								</Link>
							)}
							<div className='mt-8'>
								<CustomButton type='submit' full>
									{form === 'sign-up' ? 'Sign up' : 'Login'}
								</CustomButton>
							</div>
						</form>
					</div>
					<div className='hidden md:flex flex-col w-2/5'>
						<LoginBanner className='h-full w-full' />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	errorMessage: selectErrorMessage,
	successfulReset: selectSuccessfulReset,
});

const mapDispatchToProps = (dispatch) => ({
	_registerAsync: (name, email, password) =>
		dispatch(registerAsync(name, email, password)),
	_loginAsync: (email, password) => dispatch(loginAsync(email, password)),
	_setErrorMessage: (message) => dispatch(setErrorMessage(message)),
	_setSuccessfulReset: () => dispatch(setSuccessfulReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginWrapped);
