import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';

const client = feathers();

const init = () => {
	const { REACT_APP_BACKEND } = process.env;
	const socket = io(REACT_APP_BACKEND, {
		transports: ['websocket'],
		forceNew: true,
	});

	client.configure(
		socketio(socket, {
			// timeout: 30 * 1000,
			timeout: 5 * 1000,
		})
	);
	client.configure(auth({ storage: localStorage }));

	return client;
};

export { client, init };
