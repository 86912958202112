import React from 'react';
import PropTypes from 'prop-types';

const ItemCount = ({
	count,
	handleIncrement,
	handleDecrement,
	handleChange,
	withError,
	withArrow,
}) => {
	return (
		<div className='flex justify-between items-center bg-gray-200 p-1 md:px-2 w-16 lg:w-24'>
			<input
				type='number'
				value={count}
				onChange={handleChange}
				className={` ${
					withError ? 'border border-red-600' : 'border border-transparent'
				} text-sm md:text-md lg:text-base text-center w-full outline-none`}
			/>
			{withArrow && (
				<div className='flex flex-col'>
					<svg
						onClick={handleIncrement}
						className='w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 fill-current text-brand-color cursor-pointer'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 20 20'>
						<path d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z' />
					</svg>
					<svg
						onClick={handleDecrement}
						className='w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 fill-current text-brand-color cursor-pointer'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 20 20'>
						<path d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' />
					</svg>
				</div>
			)}
		</div>
	);
};

ItemCount.propTypes = {
	count: PropTypes.number,
	handleChange: PropTypes.func,
	handleIncrement: PropTypes.func,
	handleDecrement: PropTypes.func,
	withError: PropTypes.bool,
	withArrow: PropTypes.bool,
};

export default ItemCount;
