import React from 'react';

import CustomButton from '../custom-button/custom-button.component';
import OrderViewItem from '../order-view-item/order-view-item.component';

import { combineShippingAddress } from '../shipping-details/shipping-details.utils';

const OrderView = ({ title, handleModalToggle, item }) => {
	const { shippingDetails, cartItems } = item;
	const {
		street,
		province,
		city,
		barangay,
		landmark,
		name,
		contact,
	} = shippingDetails;

	const address = combineShippingAddress({
		street,
		province,
		city,
		barangay,
		landmark,
	});

	return (
		<div className='bg-white mx-5 px-6 py-10 w-3/4 sm:w-1/2 lg:w-1/3 xl:w-1/4'>
			<div className='flex justify-between'>
				<div className='text-lg font-bold'>{title}</div>
				<div
					className='font-extrabold cursor-pointer'
					onClick={() => {
						handleModalToggle(false);
					}}
					onKeyPress={() => {
						handleModalToggle(false);
					}}
					role='button'
					tabIndex='0'>
					x
				</div>
			</div>
			<div className='flex justify-between items-center mt-1'>
				<div className='text-xs md:text-sm'>
					<span className='font-bold'>Order #</span>
					<span className='ml-1'>{item.order_number}</span>
				</div>
				<mark
					className={`
						${item.status === 'processed' ? 'bg-orange-500 text-white' : ''}
						${item.status === 'confirmed' ? 'bg-blue-600 text-white' : ''} 
						${item.status === 'prepared' ? 'bg-yellow-400 text-gray-900' : ''} 
						${item.status === 'shipped' ? 'bg-teal-600 text-white' : ''} 
						${item.status === 'delivered' ? 'bg-green-600 text-white' : ''}
						${item.status === 'rejected' ? 'bg-red-500 text-white' : ''} 
						text-xs md:text-sm italic p-2 rounded-md
					`}>
					{item.status}
				</mark>
			</div>
			<div
				className='flex flex-col overflow-y-scroll mt-5 p-2 bg-gray-200'
				style={{
					maxHeight: 250,
					minHeight: 100,
				}}>
				{cartItems.map((item) => {
					return <OrderViewItem key={item._id} item={item} />;
				})}
			</div>
			<div className='text-xs md:text-sm mt-4 w-3/4'>
				<div className='font-bold mb-1'>Deliver to:</div>
				<div>{name}</div>
				<div>{contact}</div>
				<div className='capitalize'>{address}</div>
			</div>
			<div className='mt-5 text-center'>
				<CustomButton
					onClick={() => {
						handleModalToggle(false);
					}}
					inverted
					wide>
					OK
				</CustomButton>
			</div>
		</div>
	);
};

export default OrderView;
