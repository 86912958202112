import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';

import './with-spinner.styles.scss';

const withSpinner = (WrappedComponent) => {
	const Spinner = ({ isLoading, ...otherProps }) => {
		return isLoading ? (
			<div className='spinner-overlay flex justify-center items-center'>
				<PacmanLoader color={'#ec1f25'} size={30} />
			</div>
		) : (
			<WrappedComponent {...otherProps} />
		);
	};

	return Spinner;
};

export default withSpinner;
