import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const ProductPreview = ({
	_id,
	name,
	category,
	classification,
	packing,
	description,
	stars,
	stocks,
	imageUrl,
}) => {
	const history = useHistory();
	const rating = [];

	for (let i = 0; i < 5; i += 1) {
		rating.push(
			<svg
				key={i}
				className={`${
					i < stars ? 'text-brand-color' : 'text-gray-500'
				} w-4 h-4 inline fill-current`}
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 20 20'>
				<path d='M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z' />
			</svg>
		);
	}

	const handleClick = () => {
		history.push({
			pathname: `/products/view/${_id}`,
			state: {
				_id,
				name,
				category,
				classification,
				packing,
				description,
				stars,
				stocks,
				imageUrl,
			},
		});
	};

	return (
		<div
			className='w-1/2 md:w-1/3 lg:w-1/4 mt-4 p-2 cursor-pointer outline-none'
			role='button'
			tabIndex='0'
			onClick={handleClick}
			onKeyPress={handleClick}>
			<div className='bg-white rounded overflow-hidden shadow-lg'>
				<img
					width='500px'
					height='500px'
					src={`${imageUrl}-300x300.jpg`}
					alt={name}
				/>
				<div className='p-4 bg-gray-100'>
					<div className='font-bold text-lg leading-none truncate'>{name}</div>
					<div
						className={`${
							classification === 'none' ? 'opacity-0' : null
						} text-sm text-gray-600 uppercase font-semibold tracking-wide`}>
						{classification}
					</div>
					<div className='mt-2 text-sm text-gray-600 truncate'>
						{description}
					</div>
					<div className='mt-3'>{rating}</div>
				</div>
			</div>
		</div>
	);
};

ProductPreview.propTypes = {
	_id: PropTypes.string,
	name: PropTypes.string,
	classification: PropTypes.string,
	description: PropTypes.string,
	stars: PropTypes.number,
	stocks: PropTypes.number,
	imageUrl: PropTypes.string,
};

ProductPreview.defaultProps = {
	classification: 'none',
};

export default ProductPreview;
