import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import CustomButton from '../custom-button/custom-button.component';
import ItemCount from '../item-count/item-count.component';
import ContactForm from '../contact-form/contact-form.component';
import Modal from '../modal/modal.component';

import { addItemAsync } from '../../redux/cart/cart.actions';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import 'react-toastify/dist/ReactToastify.css';
import { createStructuredSelector } from 'reselect';

const ProductViewItem = ({ item, _addItemAsync, currentUser, history }) => {
	const [showModal, handleModalToggle] = useState(false);
	const [countType, setCountType] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [withError, setWithError] = useState(false);
	const [count, setCount] = useState(1);
	const { name, classification, packing, description, imageUrl } = item;

	const handleClick = async () => {
		if (!count || count < 1) {
			setWithError(true);
			return;
		}

		if (!countType) {
			setErrorMessage('This field is required.');
			return;
		}

		await _addItemAsync(currentUser._id, { ...item, count, countType });
		notify();
	};

	const handleUnauthenticated = () => {
		history.push('/login');
	};

	const notify = () =>
		toast.success('Item successfully added to cart', {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});

	const handleChange = (e) => {
		setWithError(false);
		setCount(e.target.value);
	};

	return (
		<div className='p-4 bg-white shadow-lg flex flex-col sm:flex-row'>
			<ToastContainer />
			<div className='w-3/4 self-center sm:w-1/2'>
				<img src={`${imageUrl}-500x500.jpg`} alt={name} />
			</div>
			<div className='w-full sm:w-1/2 p-2'>
				<h1 className='font-bold text-lg md:text-xl lg:text-2xl'>{name}</h1>
				<div className='text-sm md:text-md lg:text-base text-gray-600 capitalize mb-2'>
					{classification === 'none' ? '' : classification}
				</div>
				<div className='text-sm md:text-md lg:text-base pt-2 mb-2'>
					<div className='mb-1 italic'>Description:</div>
					{description}
				</div>
				<div className='text-sm md:text-md lg:text-base pt-2 mb-6'>
					<div className='mb-1 italic'>Packing:</div>
					{packing}
				</div>
				<div className='flex items-center p-1'>
					<ItemCount
						count={count}
						handleChange={handleChange}
						withError={withError}
					/>
					<div
						className={`${
							errorMessage
								? 'border border-red-600'
								: 'border border-transparent'
						} flex items-center ml-2 p-1`}>
						<input
							type='radio'
							name='itemCount'
							id='packs'
							value='packs'
							onClick={() => {
								setCountType('packs');
								setErrorMessage('');
							}}
						/>
						<label className='ml-2 mr-3' htmlFor='packs'>
							pack(s)
						</label>
						<input
							type='radio'
							name='itemCount'
							id='pieces'
							value='pieces'
							onClick={() => {
								setCountType('pieces');
								setErrorMessage('');
							}}
						/>
						<label className='ml-2' htmlFor='pieces'>
							piece(s)
						</label>
					</div>
					<div className='text-xs text-red-700 ml-3'>{errorMessage}</div>
					{/* <div className='px-2 text-sm md:text-md lg:text-base text-gray-600 opacity-0'>
						{stocks} in stock
					</div> */}
				</div>
				<div className='flex mt-4'>
					<div>
						<CustomButton
							onClick={() => {
								if (!currentUser) {
									return handleUnauthenticated();
								}
								handleClick();
							}}>
							ADD TO CART
						</CustomButton>
					</div>
					<div className='ml-2'>
						<CustomButton inverted onClick={() => handleModalToggle(true)}>
							CONTACT US
						</CustomButton>
					</div>
				</div>
			</div>
			<Modal showModal={showModal}>
				<ContactForm title='Contact Us' handleModalToggle={handleModalToggle} />
			</Modal>
		</div>
	);
};

ProductViewItem.propTypes = {
	item: PropTypes.object,
	currentUser: PropTypes.object,
	_addItemAsync: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_addItemAsync: (user_id, item) => dispatch(addItemAsync(user_id, item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductViewItem);
