import React from 'react';
import PropTypes from 'prop-types';

const FormInput = ({
	label,
	border,
	preDiv,
	handleChange,
	handleKeyDown,
	...otherProps
}) => {
	return (
		<div className='w-full'>
			{label ? (
				<label className='text-xxs truncate' htmlFor={otherProps.id}>
					{label}
				</label>
			) : null}
			{preDiv ? (
				<div className='flex'>
					<div className='border border-gray-500 px-2 pt-1 lg:pt-2 rounded-l-lg text-xs md:text-sm font-bold'>
						{preDiv}
					</div>
					<input
						className={`
							${border ? 'border border-gray-500' : null}
							${label ? 'lg:h-10' : 'md:h-10'}
							w-full h-8 rounded-r-lg focus:outline-none px-2 md:px-3 text-gray-700 text-xs sm:text-sm md:text-base
						`}
						{...otherProps}
						onChange={handleChange}
						onKeyDown={handleKeyDown}
					/>
				</div>
			) : (
				<input
					className={`
						${border ? 'border border-gray-500' : null}
						${label ? 'lg:h-10' : 'md:h-10'}
						w-full h-8 rounded-lg focus:outline-none px-2 md:px-3 text-gray-700 text-xs sm:text-sm md:text-base
					`}
					{...otherProps}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
				/>
			)}
		</div>
	);
};

FormInput.propTypes = {
	border: PropTypes.bool,
	preDiv: PropTypes.string,
	label: PropTypes.string,
	handleChange: PropTypes.func,
	handleKeyDown: PropTypes.func,
};

export default FormInput;
