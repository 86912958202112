import cartActionTypes from './cart.types';
import { addItem, removeItem } from './cart.utils';

const INITIAL_STATE = {
	shippingDetails: {
		street: '',
		province: '',
		city: '',
		barangay: '',
		landmark: '',
		name: '',
		contact: '',
		province_code: '',
		city_code: '',
	},
	cartItems: [],
};

const cartReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case cartActionTypes.ADD_ITEM:
			return {
				...state,
				cartItems: addItem(action.payload),
			};
		case cartActionTypes.REMOVE_ITEM:
			return {
				...state,
				cartItems: removeItem(action.payload),
			};
		case cartActionTypes.CLEAR_ITEM:
			return {
				...state,
				cartItems: state.cartItems.filter(
					(cartItem) => cartItem.product_id !== action.payload._id
				),
			};
		case cartActionTypes.SET_SHIPPING_DETAILS:
			return {
				...state,
				shippingDetails: action.payload,
			};
		case cartActionTypes.CART_SET_INITIAL_DATA:
			return {
				...state,
				cartItems: action.payload,
			};
		default:
			return state;
	}
};

export default cartReducer;
