import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import { setSuccessfulReset } from '../../redux/user/user.actions';

import { client } from '../../client';

import 'react-toastify/dist/ReactToastify.css';

class Reset extends Component {
	constructor() {
		super();

		this.state = {
			password: '',
			confirmPassword: '',
		};
	}

	handleChange = (event) => {
		const { value, name } = event.target;

		this.setState({ [name]: value });
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		try {
			const { password, confirmPassword } = this.state;
			const { history, _setSuccessfulReset } = this.props;
			const { pathname } = window.location;
			const token = pathname.split('/');

			if (password !== confirmPassword) {
				return this.notifyPasswordError();
			}

			await client
				.service('reset-password')
				.patch(null, { newPassword: password, resetToken: token[2] });

			await window.HeaderComponent.handleLogout();

			history.push('/login');
			_setSuccessfulReset();
		} catch (error) {
			this.notifyInvalidToken();

			this.setState({
				password: '',
				confirmPassword: '',
			});
		}
	};

	notifyPasswordError = () =>
		toast.error('Password did not match.', {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});

	notifyInvalidToken = () =>
		toast.error('Invalid token or expired. Please try to request again.', {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});

	render() {
		const { password, confirmPassword } = this.state;

		return (
			<div className='mx-5 sm:mx-20 lg:mx-40 xl:mx-64 my-20 text-sm lg:text-base p-6 sm:p-8 lg:px-16 bg-white text-center shadow-lg'>
				<ToastContainer />
				<h1 className='font-bold text-base sm:text-lg md:text-xl'>
					Reset Password
				</h1>
				<form onSubmit={this.handleSubmit}>
					<div className='mt-5'>
						<FormInput
							type='password'
							name='password'
							value={password}
							minLength='6'
							placeholder='Password'
							handleChange={this.handleChange}
							border
							required
						/>
					</div>
					<div className='mt-5'>
						<FormInput
							type='password'
							name='confirmPassword'
							value={confirmPassword}
							minLength='6'
							placeholder='Confirm Password'
							handleChange={this.handleChange}
							border
							required
						/>
					</div>
					<div className='mt-3'>
						<CustomButton type='submit' full>
							Submit
						</CustomButton>
					</div>
				</form>
				<div className='mt-4 text-brand-color underline text-sm'>
					<Link to='/forgot-password'>Request password reset</Link>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	_setSuccessfulReset: () => dispatch(setSuccessfulReset()),
});

export default connect(null, mapDispatchToProps)(withRouter(Reset));
