import React, { Fragment, useState } from 'react';

import Modal from '../modal/modal.component';
import OrderView from '../order-view/order-view.component';

const OrderItem = ({ item }) => {
	const [showModal, handleModalToggle] = useState(false);

	const itemLength = item.cartItems.length;
	const itemPreviewName = item.cartItems[0].name;
	const itemPreviewClass = item.cartItems[0].classification;
	const itemPreviewImage = item.cartItems[0].imageUrl;

	return (
		<Fragment>
			<div className='flex px-5 sm:px-10 lg:px-16 py-4 items-center'>
				<div className='w-1/4 text-xs md:text-base flex flex-col'>
					<div className='w-full lg:flex lg:items-center'>
						<img
							className='w-3/5 sm:w-3/4 md:w-3/5 lg:w-1/2 xl:w-1/3 inline shadow-lg'
							src={`${itemPreviewImage}-300x300.jpg`}
							alt={itemPreviewName}
						/>
						<div className='hidden xl:inline ml-2 md:ml-4'>
							<div className='text-sm lg:text-md font-bold'>
								{itemPreviewName}
							</div>
							<div
								className={`${
									itemPreviewClass === 'none' ? 'hidden' : null
								} text-xs text-gray-600 uppercase font-semibold tracking-wide`}>
								{itemPreviewClass}
							</div>
							<div className='text-xs md:text-sm text-brand-color'>
								{itemLength > 1 ? `+ ${itemLength - 1} more` : ''}
							</div>
						</div>
					</div>
					<div className='xl:hidden mt-2'>
						<div className='text-sm lg:text-md font-bold truncate'>
							{itemPreviewName}
						</div>
						<div
							className={`${
								itemPreviewClass === 'none' ? 'hidden' : null
							} text-xs text-gray-600 uppercase font-semibold tracking-wide`}>
							{itemPreviewClass}
						</div>
						<div className='text-xs md:text-sm text-brand-color'>
							{itemLength > 1 ? `+ ${itemLength - 1} more` : ''}
						</div>
					</div>
				</div>
				<div className='w-1/4 text-xs md:text-base lg:flex lg:justify-end'>
					{item.order_number}
				</div>
				<div className='w-1/4 text-xs md:text-base italic flex justify-end'>
					<mark
						className={`
							${item.status === 'processed' ? 'bg-orange-500 text-white' : ''}
							${item.status === 'confirmed' ? 'bg-blue-600 text-white' : ''} 
							${item.status === 'prepared' ? 'bg-yellow-400 text-gray-900' : ''} 
							${item.status === 'shipped' ? 'bg-teal-600 text-white' : ''}
							${item.status === 'delivered' ? 'bg-green-600 text-white' : ''}
							${item.status === 'rejected' ? 'bg-red-500 text-white' : ''} 
							 p-2 rounded-md
						`}>
						{item.status}
					</mark>
				</div>
				<div className='w-1/4 text-xs md:text-base flex justify-end underline'>
					<div
						className='cursor-pointer'
						onClick={() => {
							handleModalToggle(true);
						}}>
						details
					</div>
				</div>
			</div>
			<hr />
			<Modal showModal={showModal}>
				<OrderView
					title='Order Details'
					handleModalToggle={handleModalToggle}
					item={item}
				/>
			</Modal>
		</Fragment>
	);
};

export default OrderItem;
