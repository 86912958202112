import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { store, persistor } from './redux/store';
import { init as initClient } from './client';

import './assets/css/tailwind.output.css';
import './index.css';

initClient();

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				{/* <PersistGate persistor={persistor}> */}
				<App />
				{/* </PersistGate> */}
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
