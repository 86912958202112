import productActionTypes from './product.types';

const INITIAL_STATE = {
	searchField: '',
	isFetching: false,
	products: [],
};

const productReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case productActionTypes.SET_SEARCH_FIELD:
			return {
				...state,
				searchField: action.payload,
			};
		case productActionTypes.PRODUCT_FETCH_START:
			return {
				...state,
				isFetching: true,
			};
		case productActionTypes.SET_INITIAL_DATA:
			return {
				...state,
				products: action.payload,
				isFetching: false,
			};
		default:
			return state;
	}
};

export default productReducer;
