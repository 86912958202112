import ordersActionTypes from './orders.types';

const INITIAL_STATE = {
	orderItems: [],
};

const ordersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ordersActionTypes.ORDERS_SET_INITIAL_DATA:
			return {
				orderItems: action.payload,
			};
		default:
			return state;
	}
};

export default ordersReducer;
