import productActionTypes from './product.types';
import { client } from '../../client';

export const setSearchField = (searchString) => ({
	type: productActionTypes.SET_SEARCH_FIELD,
	payload: searchString,
});

const fetchStart = () => ({
	type: productActionTypes.PRODUCT_FETCH_START,
});

const setInitialData = (products) => ({
	type: productActionTypes.SET_INITIAL_DATA,
	payload: products,
});

export const fetchProductsAsync = () => {
	return async (dispatch) => {
		try {
			dispatch(fetchStart());
			const products = await client.service('products').find({
				query: {
					$paginate: { value: false },
				},
			});

			((values) => {
				for (let i = values.length - 1; i > 0; i--) {
					const j = Math.floor(Math.random() * (i + 1));
					[values[i], values[j]] = [values[j], values[i]];
				}

				return values;
			})(products);

			dispatch(setInitialData(products));
		} catch (e) {
			console.log(e);
		}
	};
};
