import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import CartItem from '../../components/cart-item/cart-item.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import Empty from '../../components/empty/empty.component';
import ShippingDetails from '../../components/shipping-details/shipping-details.component';
import ContactForm from '../../components/contact-form/contact-form.component';
import Modal from '../../components/modal/modal.component';
import Confirmation from '../../components/confirmation/confirmation.component.jsx';

import {
	selectCartItems,
	selectCartItemsCount,
	selectCartItemsTotalCount,
	selectShippingDetails,
} from '../../redux/cart/cart.selectors';

const Cart = ({
	cartItems,
	cartItemsCount,
	cartItemsTotalCount,
	shippingDetails,
}) => {
	const [showModal, handleModalToggle] = useState(false);
	const [showConfirmation, handleConfirmation] = useState(false);
	const [disabledButton, setDisabledButton] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (!shippingDetails.street) {
			return setDisabledButton(true);
		}
		setDisabledButton(false);
	}, [setDisabledButton, shippingDetails.street]);

	const handleClick = () => {
		history.push('/');
	};

	return (
		<div className='container flex-grow'>
			<div className='my-4 text-xs md:text-sm'>
				<span
					className='hover:underline cursor-pointer outline-none'
					role='link'
					tabIndex='0'
					onClick={handleClick}
					onKeyPress={handleClick}>
					Home
				</span>{' '}
				&gt; My Cart
			</div>
			{cartItems.map((item) => {
				return <CartItem key={item._id} item={item} />;
			})}
			{cartItemsCount === 0 ? (
				<Empty message='Cart is empty' />
			) : (
				<div>
					<ShippingDetails />
					<div className='flex justify-end mt-8 text-xl md:text-2xl mr-4'>
						Total Items: {cartItemsTotalCount}
					</div>
					<div className='flex justify-end mt-4 items-center'>
						<div className='mr-2'>
							<CustomButton
								checkout
								disabledButton={disabledButton}
								onClick={() => handleConfirmation(true)}>
								PLACE ORDER
							</CustomButton>
						</div>
						<div>
							{/* <div className='block opacity-0 sm:hidden text-sm'>blank</div> */}
							<CustomButton
								inverted
								checkout
								onClick={() => handleModalToggle(true)}>
								CONTACT US
							</CustomButton>
						</div>
					</div>
				</div>
			)}
			<Modal showModal={showModal}>
				<ContactForm title='Contact Us' handleModalToggle={handleModalToggle} />
			</Modal>
			<Modal showModal={showConfirmation}>
				<Confirmation handleConfirmation={handleConfirmation} />
			</Modal>
		</div>
	);
};

Cart.propTypes = {
	cartItems: PropTypes.array,
	cartItemsTotalCount: PropTypes.number,
	cartItemsCount: PropTypes.number,
	shippingDetails: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
	cartItems: selectCartItems,
	cartItemsCount: selectCartItemsCount,
	cartItemsTotalCount: selectCartItemsTotalCount,
	shippingDetails: selectShippingDetails,
});

export default connect(mapStateToProps)(Cart);
