import React from 'react';

import CarouselComponent from '../carousel/carousel.component';

const Banner = () => {
	return (
		<div className='flex my-4'>
			<CarouselComponent />
		</div>
	);
};

export default Banner;
