import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Switch, Redirect } from 'react-router-dom';

import Homepage from './pages/homepage/homepage.component';
import Login from './pages/login/login.component';
import ProductView from './pages/product-view/product-view.component';
import Cart from './pages/cart/cart.component';
import Orders from './pages/orders/orders.component';
import Account from './pages/account/account.component';
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import ProductCategory from './components/product-category/product-category.component';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import ForgotPassword from './pages/forgot-password/forgot-password.component';
import ResetPassword from './pages/reset-password/reset-password.component';
import PageNotFound from './components/page-not-found/page-not-found.component';

import PrivateRoute from './routes/private-route';

import { reauthAsync } from './redux/user/user.actions';
import { fetchProductsAsync } from './redux/product/product.actions';
import { selectCurrentUser } from './redux/user/user.selectors';

function App({ currentUser, _reauthAsync, _fetchProductsAsync }) {
	const [isLoaded, setIsLoaded] = useState(false);
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			// DID UPDATE
			setIsLoaded(true);
		} else {
			// DID MOUNT
			didMountRef.current = true;
			(async () => {
				await _reauthAsync();
				_fetchProductsAsync();
			})();
		}
	});

	return (
		<div className='antialiased text-gray-900 min-h-screen flex flex-col'>
			<Header />
			<ScrollToTop />
			<Switch>
				<Route exact path='/' component={Homepage} />
				<Route exact path='/products' component={ProductCategory} />
				<Route exact path='/products/view/:id' component={ProductView} />
				<Route exact path='/forgot-password' component={ForgotPassword} />
				<Route exact path='/reset-password/:token' component={ResetPassword} />
				<Route
					exact
					path='/login'
					render={(props) => {
						const { state } = props.location;
						return currentUser ? (
							<Redirect to={state ? state.from.pathname : '/'} />
						) : (
							<Login />
						);
					}}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/cart'
					component={Cart}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/orders'
					component={Orders}
				/>
				<PrivateRoute
					exact
					isLoaded={isLoaded}
					currentUser={currentUser}
					path='/account'
					component={Account}
				/>
				<Route path='*' component={PageNotFound} />
			</Switch>
			<Footer />
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_reauthAsync: () => dispatch(reauthAsync()),
	_fetchProductsAsync: () => dispatch(fetchProductsAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
