import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import phil from 'phil-reg-prov-mun-brgy';

import FormInput from '../form-input/form-input.component';
import FormSelect from '../form-select/form-select.components';
import CustomButton from '../custom-button/custom-button.component';

import { setShippingDetailsAsync } from '../../redux/cart/cart.actions';
import { selectShippingDetails } from '../../redux/cart/cart.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

class ShippingForm extends Component {
	constructor() {
		super();

		this.state = {
			street: '',
			province: '',
			city: '',
			barangay: '',
			landmark: '',
			name: '',
			contact: '',
			province_code: '',
			city_code: '',
			cityMun: [],
			barangays: [],
			contactError: '',
		};
	}

	handleSubmit = (event) => {
		event.preventDefault();

		const {
			street,
			province,
			city,
			barangay,
			landmark,
			name,
			contact,
			province_code,
			city_code,
		} = this.state;
		const {
			_setShippingDetailsAsync,
			handleCloseModal,
			handleHideOverlay,
			currentUser,
		} = this.props;

		const validatePhone = () => {
			const contactNumber = contact;
			const contactRGEX = /^\d{10}$/;
			const contactResult = contactRGEX.test(contactNumber);

			return contactResult;
		};

		if (!validatePhone()) {
			this.setState({ contactError: 'Please input a valid phone number' });
			return;
		}

		_setShippingDetailsAsync(currentUser._id, {
			street,
			province,
			city,
			barangay,
			landmark,
			name,
			contact,
			province_code,
			city_code,
		});

		this.setState({ contactError: '' });

		handleCloseModal();
		handleHideOverlay();
	};

	handleChange = (event) => {
		const { value, name } = event.target;

		this.setState({ [name]: value });
	};

	handleSelected = (event) => {
		const { value, name, selectedIndex, childNodes } = event.target;
		const index = selectedIndex;
		const optionElement = childNodes[index];
		const optionElementCode = optionElement.getAttribute('code');

		this.setState({ [name]: value });

		if (name === 'province') {
			this.setState({
				province_code: optionElementCode,
				cityMun: phil.getCityMunByProvince(optionElementCode),
			});
		}

		if (name === 'city') {
			this.setState({
				city_code: optionElementCode,
				barangays: phil.getBarangayByMun(optionElementCode),
			});
		}
	};

	async componentDidMount() {
		const sleep = (seconds) => {
			return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
		};

		await sleep(0.5);

		const { shippingDetails } = this.props;
		const {
			street,
			province,
			city,
			barangay,
			landmark,
			name,
			contact,
			province_code,
			city_code,
		} = shippingDetails;

		this.setState({
			street,
			province,
			city,
			barangay,
			landmark,
			name,
			contact,
			cityMun: phil.getCityMunByProvince(province_code),
			barangays: phil.getBarangayByMun(city_code),
		});
	}

	render() {
		const { title, handleCloseModal } = this.props;
		const {
			street,
			province,
			city,
			barangay,
			landmark,
			name,
			contact,
			cityMun,
			barangays,
			contactError,
		} = this.state;
		const { provinces } = phil;

		return (
			<div className='bg-white mx-5 px-6 py-10 w-full md:w-3/4 xl:w-3/5'>
				<form onSubmit={this.handleSubmit}>
					<div className='flex justify-between'>
						<div className='text-lg font-bold'>{title}</div>
						<div
							className='font-extrabold cursor-pointer'
							onClick={handleCloseModal}
							onKeyPress={handleCloseModal}
							role='button'
							tabIndex='0'>
							x
						</div>
					</div>
					<div className='mt-4'>
						<p className='uppercase text-xs font-bold tracking-wider'>
							Complete Address
						</p>
						<div className='flex flex-col sm:flex-row'>
							<div className='sm:w-1/2 sm:mr-2'>
								<FormInput
									type='text'
									id='street'
									name='street'
									label='House/Unit/Floor No., Building Name, Block or Lot No., Street
								Name'
									border
									handleChange={this.handleChange}
									value={street}
									required
								/>
							</div>
							<div className='sm:w-1/2 '>
								<FormSelect
									id='province'
									name='province'
									label='Province'
									options={phil.sort(provinces)}
									handleSelected={this.handleSelected}
									value={province}
									placeholder='Select Province'
									required
								/>
							</div>
						</div>
						<div className='flex flex-col sm:flex-row'>
							<div className='sm:w-1/2 sm:mr-2'>
								<FormSelect
									id='city'
									name='city'
									label='City/Municipality'
									options={phil.sort(cityMun)}
									handleSelected={this.handleSelected}
									value={city}
									placeholder='Select City/Municipality'
									required
								/>
							</div>
							<div className='sm:w-1/2'>
								<FormSelect
									id='barangay'
									name='barangay'
									label='Barangay'
									options={phil.sort(barangays)}
									handleSelected={this.handleSelected}
									value={barangay}
									placeholder='Select Barangay'
									required
								/>
							</div>
						</div>
						<div className='flex flex-col sm:flex-row'>
							<div className='sm:w-1/2 sm:mr-2'>
								<FormInput
									type='text'
									id='landmark'
									name='landmark'
									label='Landmark'
									border
									handleChange={this.handleChange}
									value={landmark}
									required
								/>
							</div>
							<div className='opacity-0 sm:w-1/2'>blank</div>
						</div>

						<p className='uppercase text-xs font-bold tracking-wider mt-2'>
							Full Name And Contact Number
						</p>
						<div className='text-xs text-red-700'>{contactError}</div>
						<div className='flex flex-col sm:flex-row'>
							<div className='sm:w-1/2 sm:mr-2'>
								<FormInput
									type='text'
									id='name'
									name='name'
									label='Full Name'
									border
									handleChange={this.handleChange}
									value={name}
									required
								/>
							</div>
							<div className='sm:w-1/2 inline-block'>
								<FormInput
									type='text'
									id='contact'
									name='contact'
									label='Contact Number'
									preDiv='+63'
									border
									handleChange={this.handleChange}
									value={contact}
									required
								/>
							</div>
						</div>
					</div>
					<div className='pt-5 text-center'>
						<CustomButton type='submit' inverted wide>
							Save
						</CustomButton>
					</div>
				</form>
			</div>
		);
	}
}

ShippingForm.propTypes = {
	title: PropTypes.string,
	shippingDetails: PropTypes.object,
	currentUser: PropTypes.object,
	handleCloseModal: PropTypes.func,
	handleHideOverlay: PropTypes.func,
	_setShippingDetailsAsync: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	shippingDetails: selectShippingDetails,
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_setShippingDetailsAsync: (user_id, data) =>
		dispatch(setShippingDetailsAsync(user_id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingForm);
