import React from 'react';
import PropTypes from 'prop-types';

const FormSelect = ({
	label,
	options,
	handleSelected,
	placeholder,
	...otherProps
}) => {
	return (
		<div className='w-full'>
			{label ? (
				<label className='text-xxs truncate' htmlFor={otherProps.id}>
					{label}
				</label>
			) : null}

			<select
				className={`
						${label ? 'lg:h-10' : 'md:h-10'}
						w-full h-8 rounded-lg focus:outline-none px-2 md:px-3 text-gray-700 text-xs sm:text-sm md:text-base border border-gray-500 bg-white capitalize
					`}
				{...otherProps}
				onBlur={handleSelected}
				onChange={handleSelected}>
				<option className='text-xs sm:text-sm md:text-base' value='' disabled>
					{placeholder}
				</option>
				{options.map((option) => {
					return (
						<option
							key={option.name}
							className='text-gray-700 text-xs sm:text-sm md:text-base capitalize'
							code={
								otherProps.name === 'province'
									? option.prov_code
									: option.mun_code
							}
							value={option.name.toLowerCase()}>
							{option.name.toLowerCase()}
						</option>
					);
				})}
			</select>
		</div>
	);
};

FormSelect.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	options: PropTypes.array,
	handleSelected: PropTypes.func,
};

export default FormSelect;
