import cartActionTypes from './cart.types';
import { client } from '../../client';
import { addItem as addItemUtils } from './cart.utils';
import { removeItem as removeItemUtils } from './cart.utils';

export const addItem = (item) => ({
	type: cartActionTypes.ADD_ITEM,
	payload: item,
});

export const removeItem = (item) => ({
	type: cartActionTypes.REMOVE_ITEM,
	payload: item,
});

export const clearItem = (item) => ({
	type: cartActionTypes.CLEAR_ITEM,
	payload: item,
});

export const setShippingDetails = (data) => ({
	type: cartActionTypes.SET_SHIPPING_DETAILS,
	payload: data,
});

export const setInitialData = (data) => ({
	type: cartActionTypes.CART_SET_INITIAL_DATA,
	payload: data,
});

export const setShippingDetailsAsync = (user_id, data) => {
	return async (dispatch) => {
		try {
			await client.service('cart').patch(null, data, { query: { user_id } });

			dispatch(setShippingDetails(data));
		} catch (e) {
			console.log(e);
		}
	};
};

export const fetchShippingDetailsAsync = (user_id) => {
	return async (dispatch) => {
		try {
			const shippingDetails = await client
				.service('cart')
				.find({ query: { user_id } });

			const {
				street,
				province,
				city,
				barangay,
				landmark,
				name,
				contact,
				province_code,
				city_code,
			} = shippingDetails.data[0];

			const newShippingDetails = {
				street,
				province,
				city,
				barangay,
				landmark,
				name,
				contact,
				province_code,
				city_code,
			};

			dispatch(setShippingDetails(newShippingDetails));
		} catch (e) {
			console.log(e);
		}
	};
};

export const addItemAsync = (user_id, cartItemToAdd, plusOne) => {
	console.log('DEBUG: ', cartItemToAdd);
	return async (dispatch) => {
		const queryCart = async () => {
			const cart = await client.service('cart').find({ query: { user_id } });

			return cart;
		};
		const cart = await queryCart();

		if (cart.data.length === 0) {
			const createdCart = await client.service('cart').create({
				cartItems: [
					{
						product_id: cartItemToAdd._id,
						count: cartItemToAdd.count,
						countType: cartItemToAdd.countType,
					},
				],
				user_id,
			});

			return dispatch(
				addItem({
					cartItems: createdCart.cartItems,
					cartItemToAdd,
					skipAddCount: true,
				})
			);
		}

		const cartItems = addItemUtils({
			cartItems: cart.data[0].cartItems,
			cartItemToAdd,
			skipAddCount: false,
			plusOne,
		});

		await client
			.service('cart')
			.patch(null, { cartItems }, { query: { user_id } });

		const updatedCart = await queryCart();

		dispatch(
			addItem({
				cartItems: updatedCart.data[0].cartItems,
				cartItemToAdd,
				skipAddCount: true,
			})
		);
	};
};

export const removeItemAsync = (user_id, cartItemToRemove) => {
	return async (dispatch) => {
		const queryCart = async () => {
			const cart = await client.service('cart').find({ query: { user_id } });

			return cart;
		};
		const cart = await queryCart();

		const cartItems = removeItemUtils({
			cartItems: cart.data[0].cartItems,
			cartItemToRemove,
		});

		await client
			.service('cart')
			.patch(null, { cartItems }, { query: { user_id } });

		const updatedCart = await queryCart();

		dispatch(
			removeItem({
				cartItems: updatedCart.data[0].cartItems,
				cartItemToRemove,
				skipMinusCount: true,
			})
		);
	};
};

export const clearItemAsync = (user_id, itemToClear) => {
	return async (dispatch) => {
		const cart = await client.service('cart').find({ query: { user_id } });

		const cartItems = cart.data[0].cartItems.filter(
			(cartItem) => cartItem.product_id !== itemToClear._id
		);

		await client
			.service('cart')
			.patch(null, { cartItems }, { query: { user_id } });

		dispatch(clearItem(itemToClear));
	};
};

export const fetchCartAsync = (user_id) => {
	return async (dispatch) => {
		const cart = await client.service('cart').find({ query: { user_id } });
		if (cart.data.length !== 0) {
			dispatch(setInitialData(cart.data[0].cartItems));
		}
	};
};
