import React from 'react';

import Banner from '../../components/banner/banner.component';
import ProductOverview from '../../components/product-overview/product-overview.component';

const Homepage = () => {
	return (
		<div className='container'>
			<Banner />
			<ProductOverview />
		</div>
	);
};

export default Homepage;
