import React from 'react';
import PropTypes from 'prop-types';

import './modal.styles.scss';

const Modal = ({ showModal, children }) => {
	return (
		<div
			className={`${showModal ? 'block' : 'hidden'}
		modal-overlay flex justify-center items-center`}>
			{children}
		</div>
	);
};

Modal.propTypes = {
	showModal: PropTypes.bool,
	children: PropTypes.element,
};

export default Modal;
