import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProductViewItem from '../../components/product-view-item/product-view-item.component';

class ProductView extends Component {
	handleClick = () => {
		const { history } = this.props;
		history.push('/');
	};

	handleChange = (e) => {
		this.setState({ count: e.target.value });
	};

	render() {
		const { location, history } = this.props;

		return (
			<div className='container flex-grow'>
				<div className='my-4 text-xs md:text-sm'>
					<span
						className='hover:underline cursor-pointer outline-none'
						role='link'
						tabIndex='0'
						onClick={this.handleClick}
						onKeyPress={this.handleClick}>
						Home
					</span>{' '}
					&gt; {location.state.name}
				</div>
				<ProductViewItem item={location.state} history={history} />
			</div>
		);
	}
}

ProductView.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default ProductView;
