import { createSelector } from 'reselect';

const selectProduct = (state) => state.product;

export const selectSearchField = createSelector(
	[selectProduct],
	(product) => product.searchField
);

export const selectProducts = createSelector(
	[selectProduct],
	(product) => product.products
);

export const selectIsFetching = createSelector(
	[selectProduct],
	(product) => product.isFetching
);
