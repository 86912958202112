import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Empty from '../../components/empty/empty.component';
import OrderItem from '../../components/order-item/order-item.component';

import { fetchOrdersAsync } from '../../redux/orders/orders.actions';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import {
	selectOrderItems,
	selectOrderItemsCount,
} from '../../redux/orders/orders.selectors';

const Orders = ({
	currentUser,
	_fetchOrdersAsync,
	orderItems,
	orderItemsCount,
}) => {
	useEffect(() => {
		_fetchOrdersAsync(currentUser._id);
	}, [_fetchOrdersAsync, currentUser._id]);

	const history = useHistory();

	const handleClick = () => {
		history.push('/');
	};
	return (
		<div className='container flex-grow'>
			<div className='my-4 text-xs md:text-sm'>
				<span
					className='hover:underline cursor-pointer outline-none'
					role='link'
					tabIndex='0'
					onClick={handleClick}
					onKeyPress={handleClick}>
					Home
				</span>{' '}
				&gt; My Orders
			</div>
			{orderItemsCount ? (
				<Fragment>
					<div className='bg-white shadow-lg flex flex-col'>
						<div className='flex p-5 mb-1 px-5 sm:px-10 lg:px-16'>
							<div className='w-1/4 text-xs md:text-base'>Product(s)</div>
							<div className='w-1/4 text-xs md:text-base xl:ml-24'>Order #</div>
							<div className='w-1/4 text-xs md:text-base flex justify-end xl:mr-10'>
								Status
							</div>
							<div className='w-1/4 text-xs md:text-base flex justify-end'>
								Action
							</div>
						</div>
						{orderItems.map((item) => {
							return <OrderItem key={item.order_number} item={item} />;
						})}
					</div>
				</Fragment>
			) : (
				<Empty message='No orders yet' />
			)}
		</div>
	);
};

const mapStateToProps = createStructuredSelector({
	currentUser: selectCurrentUser,
	orderItems: selectOrderItems,
	orderItemsCount: selectOrderItemsCount,
});

const mapDispatchToProps = (dispatch) => ({
	_fetchOrdersAsync: (user_id) => dispatch(fetchOrdersAsync(user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
