import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import Banner1 from '../../assets/images/banner1.jpg';
import Banner2 from '../../assets/images/banner2.jpg';
import Banner3 from '../../assets/images/banner3.jpg';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CarouselComponent = () => {
	return (
		<div className='w-full'>
			<Carousel
				showThumbs={false}
				showStatus={false}
				showArrows={false}
				autoPlay
				infiniteLoop>
				<div>
					<img src={Banner1} alt='Banner1' />
				</div>
				<div>
					<img src={Banner2} alt='Banner2' />
				</div>
				<div>
					<img src={Banner3} alt='Banner3' />
				</div>
			</Carousel>
		</div>
	);
};

export default CarouselComponent;
