import { client } from '../../client';
import { store } from '../store';
import { setInitialData } from '../cart/cart.actions';
import ordersActionTypes from './orders.types';

export const ordersSetInitialData = (data) => ({
	type: ordersActionTypes.ORDERS_SET_INITIAL_DATA,
	payload: data,
});

export const setOrdersAsync = (user_id) => {
	return async (dispatch) => {
		const storeCart = store.getState().cart;
		const { shippingDetails } = storeCart;
		const cartItems = storeCart.cartItems.map((item) => {
			return {
				product_id: item.product_id,
				count: item.count,
				countType: item.countType,
			};
		});
		const status = 'processed';

		await client.service('orders').create({
			user_id,
			status,
			shippingDetails,
			cartItems,
		});

		await client
			.service('cart')
			.patch(null, { cartItems: [] }, { query: { user_id } });

		dispatch(setInitialData([]));
	};
};

export const fetchOrdersAsync = (user_id) => {
	return async (dispatch) => {
		try {
			const orders = await client
				.service('orders')
				.find({ query: { user_id, $sort: { createdAt: -1 } } });

			const ordersState = orders.data.map((order) => {
				const { _id, status, cartItems, shippingDetails } = order;

				return {
					order_number: _id,
					status,
					shippingDetails,
					cartItems,
				};
			});

			dispatch(ordersSetInitialData(ordersState));
		} catch (e) {
			console.log(e);
		}
	};
};
