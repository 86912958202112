export const addItem = ({
	cartItems,
	cartItemToAdd,
	skipAddCount,
	plusOne,
}) => {
	const existingCartItem = cartItems.find(
		(cartItem) => cartItem.product_id === cartItemToAdd._id
	);

	// if (existingCartItem) {
	// 	const totalCount = existingCartItem.count + cartItemToAdd.count;

	// 	if (totalCount > existingCartItem.stocks && !plusOne) {
	// 		return cartItems;
	// 	}
	// }

	if (existingCartItem && plusOne) {
		// if (
		// 	existingCartItem &&
		// 	existingCartItem.count === existingCartItem.stocks
		// ) {
		// 	return cartItems;
		// }
		return cartItems.map((cartItem) =>
			cartItem.product_id === cartItemToAdd._id
				? { ...cartItem, count: cartItem.count + 1 }
				: cartItem
		);
	}

	if (existingCartItem) {
		return cartItems.map((cartItem) =>
			cartItem.product_id === cartItemToAdd._id && !skipAddCount
				? { ...cartItem, count: cartItem.count + cartItemToAdd.count }
				: cartItem
		);
	}

	return [...cartItems, { ...cartItemToAdd, product_id: cartItemToAdd._id }];
};

export const removeItem = ({ cartItems, cartItemToRemove, skipMinusCount }) => {
	const existingCartItem = cartItems.find(
		(cartItem) => cartItem.product_id === cartItemToRemove._id
	);

	if (existingCartItem.count === 1) {
		return cartItems;
	}

	return cartItems.map((cartItem) =>
		cartItem.product_id === cartItemToRemove._id && !skipMinusCount
			? { ...cartItem, count: cartItem.count - 1 }
			: cartItem
	);
};
