import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import CustomButton from '../custom-button/custom-button.component';
import ShippingForm from '../shipping-form/shipping-form.component';
import Modal from '../modal/modal.component';

import { fetchShippingDetailsAsync } from '../../redux/cart/cart.actions';
import { selectShippingDetails } from '../../redux/cart/cart.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import { combineShippingAddress } from './shipping-details.utils';
import './shipping-details.styles.scss';

class ShippingDetails extends Component {
	constructor() {
		super();
		this.state = {
			showModal: false,
			showOverlay: true,
		};
	}

	handleShowModal = () => {
		this.setState({ showModal: true });
	};

	handleCloseModal = () => {
		this.setState({ showModal: false });
	};

	handleHideOverlay = () => {
		this.setState({ showOverlay: false });
	};

	componentDidMount() {
		const { currentUser, _fetchShippingDetailsAsync } = this.props;

		_fetchShippingDetailsAsync(currentUser._id);
	}

	componentDidUpdate() {
		const { showOverlay } = this.state;
		const { shippingDetails } = this.props;

		if (!showOverlay) {
			return;
		}

		if (shippingDetails.name) {
			this.setState({ showOverlay: false });
		}
	}

	render() {
		const { showModal, showOverlay } = this.state;
		const { shippingDetails } = this.props;
		const {
			street,
			province,
			city,
			barangay,
			landmark,
			name,
			contact,
		} = shippingDetails;

		const address = street
			? combineShippingAddress({
					street,
					province,
					city,
					barangay,
					landmark,
			  })
			: '';

		return (
			<div>
				<div className='mt-3 bg-white shadow-lg relative'>
					{showOverlay ? (
						<div className='shipping-overlay flex justify-center items-center'>
							<CustomButton onClick={this.handleShowModal}>
								Add Shipping Details
							</CustomButton>
						</div>
					) : null}
					<div className='shipping-border' />
					<div className='p-5'>
						<div className='text-sm md:text-base lg:text-lg font-bold'>
							Shipping Details
						</div>
						<div className='flex mt-2 md:mt-3'>
							<div className='text-xs md:text-sm lg:text-base w-1/2 sm:w-1/3'>
								<div>Name and Contact Number</div>
								<div className='font-bold mt-1'>{name}</div>
								<div className='font-bold'>{contact}</div>
							</div>
							<div className='text-xs md:text-sm lg:text-base w-1/2 sm:w-2/3'>
								<div>Address</div>
								<div className='font-bold mt-1 capitalize'>{address}</div>
							</div>
						</div>
						<div className='mt-2 flex justify-end'>
							<CustomButton onClick={this.handleShowModal}>Change</CustomButton>
						</div>
					</div>
				</div>

				<Modal showModal={showModal}>
					<ShippingForm
						title='Shipping Details'
						handleCloseModal={this.handleCloseModal}
						handleHideOverlay={this.handleHideOverlay}
					/>
				</Modal>
			</div>
		);
	}
}

ShippingDetails.propTypes = {
	shippingDetails: PropTypes.object,
	name: PropTypes.string,
	contact: PropTypes.number,
	address: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
	shippingDetails: selectShippingDetails,
	currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
	_fetchShippingDetailsAsync: (user_id) =>
		dispatch(fetchShippingDetailsAsync(user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingDetails);
