import React from 'react';

import Reset from '../../components/reset/reset.component';

const ResetPassword = () => {
	return (
		<div className='container flex-grow'>
			<Reset />
		</div>
	);
};

export default ResetPassword;
