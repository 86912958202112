import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // use to access redux stuffs
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';

import Category from '../category/category.component';
import ProductPreview from '../product-preview/product-preview.component';
import ProductNotFound from '../product-not-found/product-not-found.component';

import { setSearchField } from '../../redux/product/product.actions';

import {
	selectProducts,
	selectSearchField,
} from '../../redux/product/product.selectors';

const ProductCategory = ({
	location,
	products,
	searchField,
	_setSearchField,
}) => {
	const search = queryString.parse(location.search);
	let filteredProducts;

	useEffect(() => {
		_setSearchField(search.search);
	}, [_setSearchField, search.search]);

	filteredProducts = products.filter((product) => {
		return product.category.toLowerCase().includes(searchField.toLowerCase());
	});

	if (filteredProducts.length === 0) {
		filteredProducts = products.filter((product) => {
			return product.name.toLowerCase().includes(searchField.toLowerCase());
		});
	}
	const notFound = filteredProducts.length === 0 ? <ProductNotFound /> : null;

	return (
		<div className='container flex-grow'>
			<div className='mt-4 mx-2 text-xl font-bold text-gray-900'>
				<span>Search results for </span>
				<span className='text-brand-color'>&quot;{search.search}&quot;</span>
			</div>
			<Category />
			<div className='flex-after flex-wrap justify-around'>
				{filteredProducts.map((product) => {
					return <ProductPreview key={product._id} {...product} />;
				})}
			</div>
			{notFound}
		</div>
	);
};

ProductCategory.propTypes = {
	location: PropTypes.object,
	products: PropTypes.arrayOf(PropTypes.object),
	searchField: PropTypes.string,
	_setSearchField: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
	products: selectProducts,
	searchField: selectSearchField,
});

const mapDispatchToProps = (dispatch) => ({
	_setSearchField: (searchString) => dispatch(setSearchField(searchString)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategory);
