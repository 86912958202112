import React from 'react';

const Footer = () => {
	return (
		<div className='flex flex-col'>
			<div className='mt-6 p-6 bg-white text-gray-900 border-t-4 border-brand-color text-sm md:text-base py-12'>
				{/* <div className='container justify-between flex'>
					<div className='flex w-3/4 md:w-1/2 uppercase tracking-wider text-xs sm:text-sm'>
						<div className='w-1/2'>
							<a className='footer-link' href='#testtest'>
								About Us
							</a>
							<a className='footer-link' href='#test'>
								Blog
							</a>
							<a className='footer-link' href='#test'>
								How To Order
							</a>
							<a className='footer-link' href='#test'>
								FAQs
							</a>
							<a className='footer-link' href='#test'>
								Contact Us
							</a>
						</div>
						<div className='w-1/2'>
							<a className='footer-link' href='#test'>
								Shipping & Delivery
							</a>
							<a className='footer-link' href='#test'>
								Return and Refund
							</a>
							<a className='footer-link' href='#test'>
								Terms of Use
							</a>
							<a className='footer-link' href='#test'>
								Privacy Policy
							</a>
						</div>
					</div>
					<div className='flex items-end flex-col w-1/4 md:w-1/2 text-xs sm:text-sm'>
						<div>Follow us on:</div>
						<div className='mt-1'>
							<svg
								className='w-4 h-4 sm:w-6 sm:h-6 social-media-icons'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 512 512'>
								<path d='M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h192V336h-64v-80h64v-64c0-53.024 42.976-96 96-96h64v80h-32c-17.664 0-32-1.664-32 16v64h80l-32 80h-48v176h96c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z' />
							</svg>
							<svg
								className='w-4 h-4 sm:w-6 sm:h-6 social-media-icons'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 512 512'>
								<path d='M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z' />
							</svg>
							<svg
								className='w-4 h-4 sm:w-6 sm:h-6 social-media-icons'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 512 512'>
								<path d='M352 0H160C71.648 0 0 71.648 0 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V160C512 71.648 440.352 0 352 0zm112 352c0 61.76-50.24 112-112 112H160c-61.76 0-112-50.24-112-112V160C48 98.24 98.24 48 160 48h192c61.76 0 112 50.24 112 112v192z' />
								<path d='M256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z' />
								<circle cx='393.6' cy='118.4' r='17.056' />
							</svg>
							<svg
								className='w-4 h-4 sm:w-6 sm:h-6 social-media-icons'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 512 512'>
								<path d='M490.24 113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936 80.864 322.848 80 256.064 80c-66.912 0-144.032.864-174.656 2.912-30.624 1.76-45.728 6.272-59.744 31.008C7.36 138.592 0 181.088 0 255.904v.256c0 74.496 7.36 117.312 21.664 141.728 14.016 24.704 29.088 29.184 59.712 31.264C112.032 430.944 189.152 432 256.064 432c66.784 0 143.872-1.056 174.56-2.816 30.688-2.08 45.76-6.56 59.648-31.264C504.704 373.504 512 330.688 512 256.192v-.16-.096c0-74.848-7.296-117.344-21.76-142.016zM192 352V160l160 96-160 96z' />
							</svg>
						</div>
					</div>
				</div> */}
			</div>
			<div className='bg-gray-500 text-center text-sm md:text-base lg:text-lg'>
				&#169; 2020 Cyclone. All Rights Reserved
			</div>
		</div>
	);
};

export default Footer;
