import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; // use to access redux stuffs
import { createStructuredSelector } from 'reselect';

import ProductPreview from '../product-preview/product-preview.component';
import Category from '../category/category.component';

import { selectProducts } from '../../redux/product/product.selectors';

class ProductOverview extends Component {
	constructor() {
		super();

		this.state = {
			visible: 12,
		};
	}

	loadMore = () => {
		this.setState((prev) => {
			return { visible: prev.visible + 12 };
		});
	};

	render() {
		const { visible } = this.state;
		const { products } = this.props;

		return (
			<div className='mt-6'>
				<div className='text-xl font-bold text-gray-900'>All Products</div>
				<Category />
				<div className='flex-after flex-wrap justify-around'>
					{products.slice(0, visible).map(({ _id, ...otherProps }) => {
						return <ProductPreview key={_id} _id={_id} {...otherProps} />;
					})}
				</div>
				{visible < products.length && (
					<button
						className='marign-auto w-full mt-4 md:text-lg hover:text-brand-color focus:outline-none'
						onClick={this.loadMore}
						type='button'>
						Load more
					</button>
				)}
			</div>
		);
	}
}

ProductOverview.propTypes = {
	products: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = createStructuredSelector({
	products: selectProducts,
});

export default connect(mapStateToProps)(ProductOverview);
