import React from 'react';

const ProductNotFound = () => {
	return (
		<div className='text-center my-16 text-gray-600'>
			<h1 className='text-2xl font-bold'>
				<svg
					className='w-10 h-8 fill-current text-gray-600 inline'
					viewBox='0 0 24 24'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						className='heroicon-ui'
						d='M16.32 14.9l5.39 5.4a1 1 0 01-1.42 1.4l-5.38-5.38a8 8 0 111.41-1.41zM10 16a6 6 0 100-12 6 6 0 000 12z'
					/>
				</svg>
				No Search Result
			</h1>
			<p className='text-lg my-2'>
				Sorry, we cannot find products that matches your search term.
			</p>
		</div>
	);
};

export default ProductNotFound;
