import { userActionTypes } from './user.types';
import { patchCurrentUser } from './user.utils';

const INITIAL_STATE = {
	successfulReset: false,
	isFetching: false,
	currentUser: null,
	errorMessage: '',
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case userActionTypes.SET_CURRENT_USER:
			return {
				...state,
				currentUser: action.payload,
				isFetching: false,
			};
		case userActionTypes.FETCH_START:
			return {
				...state,
				isFetching: true,
			};
		case userActionTypes.SET_SUCCESSFUL_RESET:
			return {
				...state,
				successfulReset: !state.successfulReset,
			};
		case userActionTypes.SET_ERROR_MESSAGE:
			return {
				...state,
				errorMessage: action.payload,
				isFetching: false,
			};
		case userActionTypes.PATCH_CURRENT_USER:
			return {
				...state,
				currentUser: patchCurrentUser(state.currentUser, action.payload),
			};
		default:
			return state;
	}
};

export default userReducer;
