import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import { ToastContainer, toast } from 'react-toastify';

import { patchCurrentUserAsync } from '../../redux/user/user.actions';

class AccountDetailsForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			_id: this.props._id,
			name: this.props.name,
			email: this.props.email,
		};
	}

	handleChange = (event) => {
		const { value, name } = event.target;

		this.setState({ [name]: value });
	};

	handleSubmit = async (event) => {
		event.preventDefault();

		const { _patchCurrentUserAsync } = this.props;
		const { _id, name, email } = this.state;

		await _patchCurrentUserAsync({ _id, name, email });

		this.notify();
		// handleGeneralModalToggle(false);
	};

	notify = () =>
		toast.success('Successfully updated.', {
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});

	render() {
		const { title, handleGeneralModalToggle } = this.props;
		const { name, email } = this.state;

		return (
			<div className='bg-white mx-5 px-6 py-10 w-full sm:w-3/4 md:w-1/2 xl:w-1/3'>
				<ToastContainer />
				<div className='flex justify-between'>
					<div className='text-lg font-bold'>{title}</div>
					<div
						className='font-extrabold cursor-pointer'
						onClick={() => {
							handleGeneralModalToggle(false);
						}}
						onKeyPress={() => {
							handleGeneralModalToggle(false);
						}}
						role='button'
						tabIndex='0'>
						x
					</div>
				</div>
				<div className='mt-4'>
					<form onSubmit={this.handleSubmit}>
						<FormInput
							type='text'
							id='name'
							name='name'
							label='Name'
							border
							handleChange={this.handleChange}
							value={name}
							required
						/>
						<FormInput
							type='email'
							id='email'
							name='email'
							label='Email'
							border
							handleChange={this.handleChange}
							value={email}
							required
						/>
						<div className='pt-5 text-center'>
							<CustomButton type='submit' inverted wide>
								Save
							</CustomButton>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	_patchCurrentUserAsync: (data) => dispatch(patchCurrentUserAsync(data)),
});

export default connect(null, mapDispatchToProps)(AccountDetailsForm);
